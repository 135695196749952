<template>
  <div id="content" :class="{
    closed:!open, 
    collapsed: !content.closable && collapsed && content.type != 'ar', 
    collapseder: !content.closable && collapsed && content.type == 'ar', 
    discovery:content.content == 'DISCOVERIES'}"
    v-if="content.content != ''" >
    <div class="content-inner">
      <div class="header" @click="opener">
        <h1>{{ content.title }}</h1>
        <p v-if="content.subhead">{{ content.subhead }}</p>
      </div>
      <div :class="[{discoveries:content.content == 'DISCOVERIES'},'body']">
        <the-discoveries v-if="content.content == 'DISCOVERIES'" />
        <div v-else v-html="content.content"></div>
        <div v-if="content.type == 'menu' && content.content != 'DISCOVERIES'">
          <button class="help-button" @click="$emit('help')">REREAD ONBOARDING</button>
        </div>
      </div>
      <div v-if="content.closable">
        <div :class="[{closed:!open},'close']" @click="close">CLOSE X</div>
      </div>
      <div v-else>
        <transition name="fade" mode="out-in">
          <div v-if="!collapsed" :class="[{collapsed:collapsed},'close']" @click="collapsed = true">COLLAPSE</div>
          <div v-else-if="collapsed && content.type == 'ar'" :class="[{collapseder:collapsed},'gradient']" @click="collapsed = false">EXPAND TO VIEW CONTENT</div>
          <div v-else :class="[{collapsed:collapsed},'gradient']" @click="collapsed = false">EXPAND TO VIEW CONTENT</div>
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
import TheDiscoveries from './TheDiscoveries.vue';

export default {
  components: { TheDiscoveries },
  name: 'ContentBox',
  props: {
    content: Object,
    open: Boolean
  },
  data() {
    return {
      collapsed: true,
    }
  },
  methods: {
    close() {
      this.$emit('close');
    },
    opener() {
      if(!this.content.closable) {
        this.collapsed = false;
      } 
    }
  },
  watch: {
    content() {
      this.collapsed = true;
    }
  }
}
</script>

<style scoped lang="scss">
#content {
  position: absolute;
  top: 0;
  width: 90%;
  left: 5%;
  overflow: hidden; 
  transition: all $contentTransition;
  height: 100%;
  z-index: 2;
  pointer-events: none;
  &.closed {
    top: 101vh;
    transition: all $contentTransition;
  }
  &.collapseder {
    .body {
      transition: all $contentTransition;
      overflow: hidden;
      max-height: 0;
      margin-bottom: 0;
      padding: 0 5% 0 ;
    }  
    .header {
      transition: all $contentTransition linear 1.6s;
      max-height: 0;
      overflow: hidden;
      padding-top: 24px;
      padding-bottom: 24px;
      h1 {
        transition: color $contentTransition linear $contentTransition;
        color: white;
      }
    }
  }
  &.collapsed {
    .body {
      transition: all $contentTransition;
      overflow: hidden;
      max-height: 5vh;
      margin-bottom: 0;
    }
  }
  .content-inner {
    background: $cream;
    position: absolute;
    bottom: 0;
    border-radius: 30px 30px 0 0;
    width: 100%;
    pointer-events: all;
    max-height: calc(100% - 148px)
  }
  .header {
    border-radius: 30px 30px 0 0;
    background: white;
    padding: 5%;
    box-shadow: 0px 3px 5px -3px #ccc;
    color: $dkgreen;
    h1 {
      margin: 0;
    }
    p {
      font-style: italic;
      margin: 10px 0 0;
    }
  }
  .header {
    transition: all $contentTransition;
    max-height: 20vh;
  }
  .body {
    transition: all $contentTransition;
    padding: 1% 5% 5%;
    max-height: 65vh;
    margin-bottom: 45px;
    overflow: auto;
    :deep(b) {
      color: $dkgreen;
    }
    :deep(p) {
      font-size: 16;
    }
    :deep(ul) {
      padding: 0;
      margin:0;
      font-size: .8rem;
      list-style: none;
    } 
    :deep(li) {
      padding-left: 1em;
      text-indent: -1em;
    }
    :deep(li:first-of-type:before) {
      content: '*';
      padding-right: .6em;
    }
    :deep(li:before) {
      content: '†';
      padding-right: .6em;
    }
    &.discoveries {
      padding-bottom: 0;
      max-height: calc(65vh - 70px);
      margin-bottom: 70px;
    }
  }
  .close {
    color: $ltgray;
    text-align: center;
    padding: 20px;
    background: $cream;
    position: absolute;
    bottom: 0;
    z-index: 5;
    transition: all $contentTransition;
    height: 60px;
    width: 100%;
    .closed, .collapsed, .collapseder {
      background: transparent;
      transition: all $contentTransition;
      color: rgba(0,0,0,0)
    }
  }
  .gradient {
    background: linear-gradient(rgba(0,0,0,0), $cream);
    height: 60px;
    width: 100%;
    position: absolute;
    bottom: 0;
    z-index: 5;
    transition: all $contentTransition;
    text-align: center;
    padding: 25px;
    &.collapseder {
      height: 32px;
      border-radius: 60px 60px 0 0;
      padding: 7px 0 0;
    }
  }
}
.help-button {
  background: transparent;
  border: 2px solid $dkgreen;
  color: $dkgreen;
  display: block;
  margin: 0 auto;
  padding: 5%;
  border-radius: 12px;
}
</style>
