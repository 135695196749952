<template>
  <div class="discoveries">
    <div v-for="d in discoveries" :key="d">
      <p v-if="d.icons">{{ d.title }}</p>
      <div v-if="d.icons" class="icon-container">
        <div v-for="icon in d.icons" :key="icon.id" class="icon">
          <img v-if="foundActivations.includes(icon.id)" :src="require(`../assets/images/icons/${icon.inactive}`)" />
          <img v-else :src="require(`../assets/images/icons/${icon.active}`)" />
        </div>
      </div>
    </div>
    <div class="chonk"></div>
    <div class="fade"></div>
  </div>
</template>

<script>
import Discoveries from '@/components/Discoveries';
import { mapState } from 'pinia'
import { useActivationStore } from '@/stores/activationStore'

export default {
  name: "TheDiscoveries",
  data() {
    return {
      discoveries: Discoveries,
    }
  },
  computed: {
    ...mapState(useActivationStore, ['foundActivations']),
  }
}
</script>

<style scoped lang="scss">
.icon {
  // width: 25%;
  margin-bottom: 1%;
  &-container {
    display: grid;
    grid-template-columns: repeat(4 ,1fr);
    row-gap: 5%;
    flex-wrap: wrap;
  }
  img {
    width: 80%;
  }
}
.chonk {
  height: 90px;
  width: 100%;
}
.fade {
  background: linear-gradient(rgba(0,0,0,0), $cream);
    height: 70px;
    width: 100%;
    position: absolute;
    bottom: 60px;
    z-index: 5;
}
</style>