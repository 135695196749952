import { defineStore } from "pinia";

export const useActivationStore = defineStore("activationStore", {
  state: () => ({
    activationIdx: null,
    foundActivations: [],
  }),
  actions: {
    setActivationIdx(activation) {
      this.activationIdx = activation;
    },
    async addToFoundActivations(idx) {
      this.foundActivations.push(idx);
      this.foundActivations = [... new Set(this.foundActivations)];
      localStorage.setItem('activations', JSON.stringify(this.foundActivations));
    }
  }
});
