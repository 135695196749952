<template>
  <div id="onboarding">
    <Carousel ref="onboardingCarousel">
    <Slide v-for="slide in slides" :key="slide.step">
      <div class="carousel__item">
        <h2>{{ slide.title }}</h2>
        <img class="slide-img" :src="require(`../assets/images/${slide.img}`)" />
        <h3>STEP {{ slide.step }}</h3>
        <p>{{ slide.info }}</p>
      </div>
    </Slide>
    <template #addons>
      <Pagination />
    </template>
  </Carousel>
  <button class="next-button" @click="next">{{ buttonWords }}</button>
  <a class="skip-button" @click="skip">SKIP</a>
  </div>
</template>

<script>
import 'vue3-carousel/dist/carousel.css';
import { Carousel, Pagination, Slide } from 'vue3-carousel';

export default {
  name: 'OnboardingOverlay',
  components: {
    Carousel,
    Slide,
    Pagination,
  },
  data() {
    return {
      slides: [
        {step: 1, title: "ACTIVATE CAMERA", info: "Tap the AR camera icon located on the top right corner of the screen on your mobile device.", img: "ar.png"},
        {step: 2, title: "SCAN", info: "To activate the AR experience or animation, locate markers similar to the example provided using your mobile devices camera in AR mode", img: "scan.png"},
        {step: 3, title: "PREVIEW", info: "Experience AR and animations on your mobile device. Tap hotspots to discover captivating content.", img: "preview.png"},
      ],
      buttonWords: "NEXT"
    }
  },
  methods: {
    skip() {
      this.$emit('onboarded');
    },
    advance() {
      this.$refs.onboardingCarousel.next()
      if(this.$refs.onboardingCarousel.data.currentSlide._value == 2) this.buttonWords = "GOT IT";
    },
    next() {
      this.$refs.onboardingCarousel.data.currentSlide._value == 2 ? this.skip() : this.advance();
    }
  }
}
</script>

<style lang="scss">
#onboarding {
  background: $cream;
  position: absolute;
  bottom: 0;
  height: 100vh;
  width: 100vw;
  left: 0;
  z-index: 100;
}

.carousel__slide {
  padding: 10px;
}
.carousel__pagination {
  padding: 0;
  &-button {
    padding: 10px;
    &::after {
      width: 15px;
      height: 15px;
      border-radius: 50%;
      background: $cream;
      border: 2px solid $dkgreen;
    }
    &--active::after {
      background: $dkgreen;
    }
  }
}

.carousel__prev,
.carousel__next {
  box-sizing: content-box;
  border: 5px solid white;
}
.carousel__item {
  height: 65vh;
  .slide-img {
    height: 90px;
    margin-bottom: 10%;
  }
  & h2 {
    font-size: 24px;
    margin: 15% 0 15%;
  }
  & p {
    text-align: left;
    padding: 0 10%;
  }
}
.next-button {
  background: $dkgreen;
  border: none;
  color: white;
  width: 30vw;
  height: 5vh;
  display: block;
  margin: 3vh auto;
  border-radius: 12px;
}
.skip-button {
  display: block;
  margin: 5vh auto;
  text-align: center;
  text-decoration: underline;
  border-radius: 12px;
}
</style>