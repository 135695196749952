<template>
  <div>
    <video v-if="content.type == 'video'" :src="vidSrc" muted autoplay loop playsinline></video>
    <img v-else :src="vidSrc" />
  </div>
</template>

<script>
export default {
  name: 'TheVideo',
  props: {
    content: Object
  },
  computed: {
    vidSrc() {
      return process.env.BASE_URL + 'media/' + this.content.file;
    }
  },
  mounted() {
    console.log(this.content);
  }
}
</script>

<style scoped lang="scss">
div {
  position: absolute;
  top:0;
  left:0;
  height: 100vh;
  width: 100vw;
  background-color: black;
}
video, img {
  width: 100vw;
  mask-image: linear-gradient(0deg, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 15%)
}
</style>