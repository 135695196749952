<template>
  <div id="home">
    <div class="orientation-warning"><p>Please rotate device to portrait mode</p></div>
    <div :class="[{completed: currActivation.type == 'done'},'complete']"><p><span>Congratulations!</span></p><p>You've completed all the stories! Take this to the Treehouse and have a coffee on us.</p></div>
    <transition name="fade-undelayed">
      <onboarding-overlay @onboarded="onboarded" v-if="onboarding !== 'false'" />
    </transition>
    <preview-bubble v-if="preview && currActivation.title != ''" :name="currActivation.title" @confirm="openContent(currActivationName)" />
    <transition name="fade">
      <div v-if="cameraActive" class="crosshair"><img src="../assets/images/crosshair.png" /></div>
    </transition>
    <menu-overlay :menuData="menuData" @info="openContent('WELCOME')" @grid="openContent('DISCOVERIES')" @camera="openCamera" />
    <content-box :open="contentOpen" :content="currActivation" @close="openCamera" @help="onboard" />
    <the-video v-if="currActivation && (currActivation.type == 'video' || currActivation.type == 'image') && contentOpen" :content="currActivation" />
    <the-ar v-else-if="currActivation && currActivation.type == 'ar' && contentOpen" :content="currActivation" />
  </div>
</template>

<script>
import MenuOverlay from '@/components/MenuOverlay.vue'
import OnboardingOverlay from '@/components/OnboardingOverlay.vue'
import ContentBox from '@/components/ContentBox.vue'
import Discoveries from '@/components/Discoveries';
import TheVideo from '@/components/TheVideo';
import TheAr from '@/components/TheAr';
import { mapActions, mapState } from 'pinia'
import { useActivationStore } from '@/stores/activationStore'
import PreviewBubble from '@/components/PreviewBubble.vue'

export default {
  name: 'HomeView',
  components: {
    MenuOverlay,
    OnboardingOverlay,
    ContentBox,
    PreviewBubble,
    TheVideo,
    TheAr,
  },
  data() {
    return {
      menuPosition: "top",
      contentOpen: true,
      currActivationName: "WELCOME",
      cameraActive: false,
      rawDiscoveries: Discoveries,
      preview: false,
      timeout: null,
      onboarding: 'true',
    }
  },
  computed: {
    ...mapState(useActivationStore, ['activationIdx', 'foundActivations']),
    currActivation() {
      if(this.rawDiscoveries.MENUS[this.currActivationName]) {
        return this.rawDiscoveries.MENUS[this.currActivationName];
      } else {
        return this.allDiscoveries.find(d => d.name == this.currActivationName)
      }
    },
    menuData() {
      return { menuPosition: this.menuPosition, cameraActive: this.cameraActive, currActivationName: this.currActivationName, currActivation: this.currActivation }
    },
    allDiscoveries() {
      let all = [...this.rawDiscoveries.COLUMNS.icons, ...this.rawDiscoveries.LIVING.icons, ...this.rawDiscoveries.RESEARCH.icons ];
      return all;
    }
  },
  watch: {
    activationIdx(newVal) {
      newVal != null ? this.openPreview(this.allDiscoveries.find(d => d.id == newVal).name) : this.openCamera();
    }
  },
  methods: {
    ...mapActions(useActivationStore, ['setActivationIdx', 'addToFoundActivations']),
    onboard() {
      this.$cookies.set('onboarding', 'true')
      this.onboarding = 'true';
    },
    onboarded() {
      this.$cookies.set('onboarding', 'false')
      this.onboarding = 'false';
    }, 
    resetTimeout() {
      clearTimeout(this.timeout);
      this.timeout =  null;
    },
    closeContent() {
      if (this.currActivation.type == 'ar') window.location.replace('https://4500lobbyexperience.americancentury.mobi/index.html');
      this.resetTimeout();
      this.menuPosition = "bottom";
      this.contentOpen = false;
      this.preview = false;
      this.setActivationIdx(null)
      if (this.allDiscoveries.length == this.foundActivations.length) {
        this.timeout = setTimeout(() => this.currActivationName = 'DONE', 2000);
      } else {
        this.timeout = setTimeout(() => this.currActivationName = 'CLEAR', 2000);
      }
    },
    openPreview(name) {
      this.resetTimeout();
      this.currActivationName = name;
      this.preview = true;
      this.contentOpen = false;
    },
    openContent(name) {
      this.resetTimeout();
      this.preview = false;
      this.cameraActive = false;
      this.currActivationName = name;
      this.menuPosition = "top";
      this.contentOpen = true;
    },
    openCamera() {
      this.closeContent();
      this.cameraActive = true;
    }
  },
 async mounted() {
    if (this.$cookies.isKey('onboarding')) {
      this.onboarding = this.$cookies.get('onboarding');
      localStorage.setItem('activations', this.$cookies.get('activations'));
      const found = this.$cookies.get('activations');
      for(let i = 0; i < found.length; i++) {
        this.addToFoundActivations(parseInt(found[i]));
      }
      if (this.onboarding == 'false') {
        this.openCamera();
      }
    }
    window.addEventListener("arjs", async (e) => { 
      console.log('scanned. cameraActive: ',this.cameraActive)
      console.log('scanned details: ', e)
      if (this.cameraActive) {
        this.setActivationIdx(e.detail);
        await this.addToFoundActivations(parseInt(e.detail));
        this.$cookies.set('activations', localStorage.getItem('activations'));
      }
    }, false );
  }
}
</script>

<style scoped lang="scss">
#home {
  background: transparent;
  height: 100vh;
  width: 100vw;
  .crosshair {
    position: absolute;
    top: 30%;
    left: 0;
    width: 100%;
    text-align: center;
  }
}
.complete {
  &.completed {
    display: flex;
  }
  display: none;
  flex-direction: column;
  background: $cream;
  height: 100vh;
  width: 100vw;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9999;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 0 10%;
  p {
    text-transform: uppercase;
    font-weight: bold;
    font-size: 20px;
    margin: 0 0 25px 0;
    span {
      font-weight: bold;
      font-size: 30px;
    }
  }
}
</style>
