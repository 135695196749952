import HomeView from "../views/HomeView.vue";
import { createRouter, createWebHashHistory } from 'vue-router';

const routes = [
  {
    path: "/",
    name: "Home",
    component: HomeView,
    meta: { color: "blue" }
  },
];

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes
});


export default router;
