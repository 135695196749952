<template>
  <div id="menu-overlay" :class="menuData.menuPosition">
    <transition name="fade">
      <div class="main close" :class="menuData.menuPosition" v-if="menuData.currActivation.type != 'menu' && menuData.menuPosition == 'top'">
        <div class="icon" @click="$emit('camera')"><img src="@/assets/images/close.png" /></div>
      </div>
      <div class="main" :class="menuData.menuPosition" v-else>
        <transition name="fade">
          <div v-if='!menuData.cameraActive' class="icon" @click="$emit('camera')"><img src="@/assets/images/camera.png" /></div>
        </transition>
        <div :class="[{inactive:!menuData.cameraActive && menuData.currActivationName == 'DISCOVERIES'},'icon']" @click="$emit('grid')"><img src="@/assets/images/grid.svg" /></div>
        <transition name="fade">
          <div class="info icon" @click="$emit('info')" v-show="menuData.menuPosition == 'bottom'"><img src="@/assets/images/info.svg" /></div>
        </transition>
      </div>
    </transition>
  </div>
</template>

<script>

export default {
  name: 'MenuOverlay',
  props: {
    menuData: Object
  }
}
</script>

<style scoped lang="scss">
#menu-overlay {
  .main {
    width: 100vw;
    position: absolute;
    display:flex;
    flex-direction: column;
    left: 0;
    align-items: flex-end;
    transition: all 2s;
    z-index: 99;
    &.top {
      bottom: calc(100% - 148px);
      &.close {
        bottom: calc(100% - 74px);
      }
    }
    &.bottom {
      bottom: 0;
    }
  }
  .icon {
    height: 44px;
    width: 44px;
    background: white;
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 10px 15px;
    transition: opacity 1s;
    &.inactive {
      opacity: .5;
    }
    &:first-of-type {
      margin-top: 30px;
    }
    &.info {
      position: absolute;
      bottom: 0;
      left: 0;
    }
  }
}
</style>
