<template>
  <div>
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'App'
}
</script>

<style lang="scss">
@import "@/assets/scss/main";
.stream {
  position: absolute;
  top: 0;
  z-index: -1;
  height: 100%;
  width: 100%;
  object-fit: cover;
}
</style>
