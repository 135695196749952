export default {
  MENUS: {
    DONE: {
      type: 'done',
      title: '',
      subhead: '',
      content: '',
      closable: false
    },
    CLEAR: {
      type: 'menu',
      title: '',
      subhead: '',
      content: '',
      closable: true
    },
    WELCOME: {
      type: 'menu',
      title: 'Welcome to Our AR Lobby Experience!',
      subhead: 'We\'re glad you\'re here',
      content: "<p><b>Walk around, view our stories</b></p><p>All around our lobby space are memorable highlights about American Century Investments<sup>®</sup>. Some are inspiring, some humorous&#8212;but all give insight into who we are and how we're different.</p><p>Hold your phone's camera to each icon you see to get the unique backstory.</p><p>Thank you for exploring!</p>",
      closable: true
    },
    DISCOVERIES: {
      type: 'menu',
      title: 'Your Discoveries',
      subhead: 'See what you\'ve explored',
      content: "DISCOVERIES",
      closable: true
    },
  },
  RESEARCH: {
    title: "Stowers Research Wall",
    icons: [
      {
        id: 1, 
        active: "S01.png", 
        inactive: "S01_gray.png", 
        type: 'video',
        file: 'S01.mp4',
        name: "S01",
        title: 'An Audacious Idea. Humanity Wins.',
        subhead: 'Changing lives through medical research',
        content: "<p>As cancer survivors, American Century<sup>®</sup> founders Jim and Virginia Stowers knew the hopeless feeling of a life-threatening disease. Using their personal experiences and virtually all their wealth, they sought to do something courageous and offer hope.</p><p>The result? The Stowers Institute for Medical Research. A remarkable place where scientists tackle the riskiest and most difficult questions that biology presents us today. All for the ultimate goal—to prevent and cure diseases that touch us all.</p>",
        closable: false
      },
      {
        id: 2, 
        active: "S02.png", 
        inactive: "S02_gray.png", 
        type: 'video',
        file: 'S02.mp4',
        name: "S02",
        title: 'Giving Back. Giving Big.',
        subhead: 'Stowers\' generosity gets noticed',
        content: "<p>When someone donates nearly all their wealth while still living, it's big news. Jim and Virginia Stowers made headlines by joining Warren Buffet in the “Giving Pledge,” promising 99% of their wealth to charity<sup>*</sup>. They made headlines again, when Forbes listed them among the “Biggest Givers” for endowing the Stowers Institute for Medical Research with more than $2 billion<sup>†</sup>.</p><p>The couple appreciated the accolades for their fight against cancer. Truthfully, they would have given anyway.</p><ul><li>Givingpledge.org/pledgers, Jim and Virginia Stowers, 2010.</li><li>Forbes, The World's Biggest Givers, May 19, 2011.</li></ul>",
        closable: false
      },
      {
        id: 3, 
        active: "S03.png", 
        inactive: "S03_gray.png", 
        type: 'video',
        file: 'S03.mp4',
        name: "S03",
        title: 'Secrets of Life. Uncovered.',
        subhead: 'Sculpture conveys Stowers\' powerful mission',
        content: "<p>As a promise to humanity, the Hope for Life<sup>*</sup> sculpture greets visitors and passersby at the Stowers Institute for Medical Research every day.</p><p>Two strands represent a DNA helix. Between them, a human form signifies the Institute's offer of hope against devastating illnesses. Together, these symbols embody how over 500 scientists and staff perform foundational research to discover how genes determine our biological fate. Their aim is profound: improve human health for all.</p><ul><li>Hope for Life was created by globally renowned sculptor Larry Young.</li></ul>",
        closable: false
      },
      {
        id: 4, 
        active: "S04.png", 
        inactive: "S04_gray.png", 
        type: 'video',
        file: 'S04.mp4',
        name: "S04",
        title: '40% of Profits. Lives Changed.',
        subhead: 'Ownership impact and inspiration',
        content: "<p>Many donate to worthy causes. Not everyone gives away an investment firm they spent decades building. Jim and Virginia Stowers did. Their gift gives uninterrupted support to the Stowers Institute for Medical Research.</p><p>For us, two powerful aspirations unite: help clients succeed financially and end life-threatening diseases. Directing 40% of our profits<sup>*</sup> to the Institute annually isn't just a transaction. It's inspiring. The better we do for clients, the better we do for our world.</p><ul><li>Dividends to the Stowers Institute have totaled nearly $2 billion since 2000.</li></ul>",
        closable: false
      },
      {
        id: 5, 
        active: "S06.png", 
        inactive: "S06_gray.png", 
        type: 'video',
        file: 'S06.mp4',
        name: "S06", //not a typo
        title: 'Developing Therapies. Breaking Barriers.',
        subhead: 'Moving from research to treatments',
        content: "<p>Jim and Virginia Stowers, founders of American Century<sup>®</sup>, wanted a place where therapies could be developed—especially for difficult-to-treat diseases. That's how BioMed Valley Discoveries came to be.</p><p>How does it work? The Stowers Institute for Medical Research uncovers scientific breakthroughs. BioMed Valley Discoveries translates foundational research findings into potential cures. 100% of the net profits from treatments will go back to support the mission of the Stowers Institute.</p>",
        closable: false
      },
      {
        id: 6, 
        active: "S07.png", 
        inactive: "S07_gray.png", 
        type: 'video',
        file: 'S07.mp4',
        name: "S07",
        title: 'Alzheimer\'s',
        subhead: 'How does the brain make memories?',
        content: "<p>With approximately 86 billion neurons, the brain is one of the most complex systems in the world. By studying healthy brains, Stowers scientists are examining how memories are formed and what goes wrong in neurodegenerative diseases like Alzheimer's and Parkinson's.</p><p>Learn more about this research <a href='https://www.stowers.org/impact/impact-brain-and-memory' target='_blank'>here.</a></p></p>",
        closable: false
      },      
      {
        id: 7, 
        active: "S08.png", 
        inactive: "S08_gray.png", 
        type: 'video',
        file: 'S08.mp4',
        name: "S08",
        title: 'Diabetes',
        subhead: 'How does food convert to energy?',
        content: "<p>Stowers scientists are investigating the metabolism of cavefish, a natural model to study adaptation to overeating, to potentially help determine targets for drugs treating metabolic conditions in humans, like diabetes and obesity.</p><p>Learn more about this research <a href='https://www.stowers.org/news/researchers-look-cavefish-for-insight-metabolism' target='_blank'>here.</a></p>",
        closable: false
      },
      {
        id: 8, 
        active: "S09.png", 
        inactive: "S09_gray.png", 
        type: 'video',
        file: 'S09.mp4',
        name: "S09",
        title: 'Cancer',
        subhead: 'Why and how do cells mutate?',
        content: "<p>Stowers scientists are studying cell growth and function, and how these processes are disrupted in cancer. They have identified ways to expand adult stem cells from human umbilical cord blood in culture in order to generate therapeutic quantities for transplants to treat leukemia and other cancers.</p><p>Learn more about this research <a href='https://www.stowers.org/our-impact-cancer' target='_blank'>here.</a></p>",
        closable: false
      },
      {
        id: 9, 
        active: "S10.png", 
        inactive: "S10_gray.png", 
        type: 'video',
        file: 'S10.mp4',
        name: "S10",
        title: 'Infertility',
        subhead: 'What happens during meiosis?',
        content: "<p>Foundational research at the Stowers Institute focuses on genetic, molecular and cellular contributions to infertility. Multiple research programs study how the integrity of chromosomes is maintained and how they are distributed during meiosis, the specialized cell division that produces eggs and sperm.</p><p>Learn more about this research <a href='https://www.stowers.org/impact/impact-infertility' target='_blank'>here.</a></p>",
        closable: false
      },
      {
        id: 10, 
        active: "S11.png", 
        inactive: "S11_gray.png", 
        type: 'video',
        file: 'S11.mp4',
        name: "S11",
        title: 'Congenital Conditions',
        subhead: 'What happens during early development?',
        content: "<p>Neural crest cell research at Stowers has uncovered genetic, molecular and cellular mechanisms causing Treacher-Collins Syndrome, which affects development of the head and face, and Hirschsprung's disease, which affects nerves of the intestines.</p><p>Ongoing research aims to uncover more precisely how genetic alterations cause congenital conditions to reveal new ways for treatment or prevention.</p><p>Learn more about this research <a href='https://www.stowers.org/news/decoding-craniofacial-defects' target='_blank'>here.</a></p>",
        closable: false
      },
    ]
  },
  COLUMNS: {
    title: "Columns",
    icons: [
      {
        id: 12, //ROCKET
        active: "CN01.png", 
        inactive: "CN01_gray.png", 
        type: 'ar',
        file: 'https://dimin.8thwall.app/aci-rocket/',
        name: "CN01",
        title: 'Rocket Man Stands Up. Wins.', 
        subhead: 'Jim\'s passion helped us soar',
        content: "<p>Jim Stowers stood for what was right. And fair. In 1965 when the SEC said his rocket logo should point down instead of up (“up” implied his fund yields would always rise), he disagreed.</p><p>Referring to Dreyfus commercials showing its lion roaring from Wall Street's subway station, Jim's response was classic. “If you kill the lion and put its feet in the air, I would be willing to point my rocket down.” The rocket stayed.</p>",
        closable: false
      },
      {
        id: 13, //TREE
        active: "CN02.png", 
        inactive: "CN02_gray.png", 
        type: 'ar',
        file: 'https://dimin.8thwall.app/aci-tree/',
        name: "CN02",
        title: 'Sustainability. It\'s in Our Genes.<sup>®</sup>', 
        subhead: '',//THESE ARE BLANK ON PURPOSE - CONTENT IS IN THE AR
        content: "",
        closable: false
      },
      {
        id: 14, //SANDWICH
        active: "CN03.png", 
        inactive: "CN03_gray.png", 
        type: 'image',
        file: 'CN03.png',
        name: "CN03",
        title: 'Serving Clients. It\'s Tradition.', 
        subhead: 'The peanut butter is proof',
        content: "<p>After 65 years, we've seen growth, championed innovation and experienced firsts. First proprietary stock analysis. First financial firm owned by a medical research organization. And first semitransparent actively managed ETFs.</p><p>But we're also big on tradition. Like watching our founder eat a peanut butter sandwich every day, then fold the sack to use again tomorrow. And letting our “clients first” mantra guide us. Your success is our priority. The proof is in the peanut butter.</p>",
        closable: false
      },
      {
        id: 15, //EGG
        active: "CN04.png", 
        inactive: "CN04_gray.png", 
        type: 'ar',
        file: 'https://dimin.8thwall.app/aci-tennis-ball-egg', 
        name: "CN04",
        title: 'Eggs? Tennis Balls? Invest Wisely.', 
        subhead: 'Choosing a long-term view',
        content: "<p>The Black Monday market plunge of October 1987 was scary. Jim Stowers reminded employees, “When bad stocks hit the bottom, they crash like an egg. When markets go up, good stocks (tennis balls) spring back to life. We invest in tennis balls!”</p><p>To prove the point, an egg and tennis ball were dropped from a ladder. The egg splattered. The tennis ball bounced impressively. It's a good reminder—investing is for the long term.</p>",
        closable: false
      },
      {
        id: 16, 
        active: "CS01.png", 
        inactive: "CS01_gray.png", 
        type: 'video',
        file: 'CS01.mp4',
        name: "CS01",
        title: 'Clients First. Period.', 
        subhead: 'Because everyone deserves financial success',
        content: "<p>This former pilot, cadet, musician and medical student pursued many interests before focusing on investing. So, it was no surprise when Jim Stowers brought a unique vision to his new venture: “If we make others successful, then we, in turn, will be successful.”</p><p>In 1958, with three employees, two mutual funds and $100,000 in borrowed assets, Jim launched what is now American Century Investments<sup>®</sup>. And for over 60 years, that client-first focus has not wavered.</p>",
        closable: false
      },
      {
        id: 17, //LOGO
        active: "CS02.png", 
        inactive: "CS02_gray.png", 
        type: 'image',
        file: 'CS02.png',
        name: "CS02",
        title: 'Logos Change. Values Remain.', 
        subhead: 'Designs led by client commitment',
        content: "<p>From a rocket ship to a tree, our logo and name have undergone some big changes since 1958. Each featured an aspect of who we are. And what we stand for.</p><p>Modernizing these elements did not change the strong vision our founder had for this firm. In fact, each logo supported our north star: financial independence. For everyone.</p>",
        closable: false
      },
      {
        id: 18, //MAP
        active: "CS03.png", 
        inactive: "CS03_gray.png", 
        type: 'ar',
        file: 'https://dimin.8thwall.app/aci-map', 
        name: "CS03",
        title: 'Global Reach', //THESE ARE BLANK ON PURPOSE - CONTENT IS IN THE AR
        subhead: '',
        content: "",
        closable: false
      },
      {
        id: 19, 
        active: "CS04.png", 
        inactive: "CS04_gray.png", 
        type: 'video',
        file: 'CS04.mp4',
        name: "CS04",
        title: 'Diversity, Equity, Inclusion. It Matters.', 
        subhead: 'Change starts with growth',
        content: "<p>We will never truly complete our DEI work. It will always be a journey of challenging what we think we know, encouraging curiosity and inspiring open conversations.</p><p>Our goal is to: <br/><b>LEARN</b> more about ourselves and each other.<br /><b>UNLEARN</b> beliefs and bias holding us back.<br /><b>RELEARN</b> the behaviors and actions most likely to move our business forward.</p>",
        closable: false
      }
    ]
  },
  LIVING: {
    title: "Stowers Legacy Wall",
    icons: [
      {
        id: 20, //BATHTUB
        active: "L01.png", 
        inactive: "L01_gray.png", 
        type: 'ar',
        file: 'https://dimin.8thwall.app/aci-bathtub/', 
        name: "L01",
        title: 'Manual Research. Then, Inspiration Knocks.',
        subhead: 'Innovating the stock-picking process',
        content: "<p>Early on, our firm needed a way to analyze companies faster. We could only evaluate about 35 a day because finding unique growth characteristics takes time. We're picky that way.</p><p>In the '70s, sitting in a hotel bathtub—to avoid waking his wife —our founder, Jim Stowers, wrote a computer program to analyze 400 stocks daily. That's transformational! Today, we search for the same growth principles. But now it's tens of thousands of stocks worldwide each day.</p>",
        closable: false
      },
      {
        id: 21, 
        active: "L02.png", 
        inactive: "L02_gray.png", 
        type: 'video',
        file: 'L02.mp4',
        name: "L02",
        title: 'Kansas City. Modest Start.',
        subhead: 'Our growing needs',
        content: "<p>From humble beginnings in a basement office, 12 employees move into a larger space at the Skelly Building on the Country Club Plaza in 1966.</p>",
        closable: false
      },
      {
        id: 22, 
        active: "L03.png", 
        inactive: "L03_gray.png", 
        type: 'video',
        file: 'L03.mp4',
        name: "L03",
        title: 'Kansas City. Place Called Home.',
        subhead: 'Our headquarters officially open',
        content: "<p>Growing out of the Skelly Building, nearly 400 employees move into their impressive new digs, called the Towers, at 4500 Main Street in 1988.</p>",
        closable: false
      },
      {
        id: 23, 
        active: "L04.png", 
        inactive: "L04_gray.png", 
        type: 'video',
        file: 'L04.mp4',
        name: "L04",
        title: 'Kansas City. Expanding Presence.',
        subhead: 'Our call center location',
        content: "<p>Continuing the KC expansion, we purchased 4400 Main Street in 2006. This building is a hub to serve clients—by phone and at its central Investors Center.</p>",
        closable: false
      },
      {
        id: 24, 
        active: "L05.png", 
        inactive: "L05_gray.png", 
        type: 'video',
        file: 'L05.mp4',
        name: "L05",
        title: 'Financial Independence. That\'s the Goal.',
        subhead: 'Our founder, the author',
        content: "<p>Jim Stowers learned an incredible amount by founding American Century Investments<sup>®</sup>. His hope was to teach and inspire others to financial independence. And boy, has he.</p><p><b>Yes, You Can...Achieve Financial Independence</b><br /> This book provides money management strategies for combating the shrinking value of a dollar. And shows how investing even small amounts, over time, can help toward your goals. Plus, demonstrates how to make a plan—and improve your financial position.</p>",
        closable: false
      },
      {
        id: 25, 
        active: "L06.png", 
        inactive: "L06_gray.png", 
        type: 'video',
        file: 'L06.mp4',
        name: "L06",
        title: 'Reach Goals. Achieve Dreams.',
        subhead: 'Our founder, the author',
        content: "<p>Jim Stowers started humbly. He used the skills he picked up from his parents, other mentors and his many experiences to achieve great success—and shares those insights.</p><p><b>Yes, You Can...Reach Your Goals and Achieve Your Dreams</b><br />Using the lessons he learned founding an investment firm from a one-bedroom apartment, Jim reveals the business basics he honed. And the core attributes of innovators and entrepreneurs—from understanding yourself to interacting with others.</p>",
        closable: false
      },
      {
        id: 26, 
        active: "L07.png", 
        inactive: "L07_gray.png", 
        type: 'video',
        file: 'L07.mp4',
        title: 'Life Lessons. Learned.',
        subhead: 'Our founder, the author',
        content: "<p>Jim Stowers was the epitome of perseverance. He wanted others to learn the secrets (and not-so-secrets) he used to climb to the top—and then why he gave it all away.</p><p><b>The Best Is Yet to Be</b><br/> A fascinating, behind-the-scenes perspective on the beginnings of American Century Investments<sup>®</sup> and the Stowers Institute for Medical Research. It's filled with lessons that can help turn dreams—about life, career and finances—into reality.</p>",
        closable: false
      },
      {
        id: 27, 
        active: "L08.png", 
        inactive: "L08_gray.png", 
        type: 'video',
        file: 'L08.mp4',
        name: "L08",
        title: 'Our Kids. Financially Sound.',
        subhead: 'Our founder, the author',
        content: "<p>Jim Stowers believed that starting money education early was critical to making sound financial decisions throughout life. And we still believe—and advocate for—that principle today. </p><p><b>Raising Financially Aware Kids</b><br /> This book is for parents, grandparents and teachers—or anyone helping kids from toddlers to teens learn about money. It's a program for financial education with takeaways every child should know. Including the all-important: how to establish saving and investing habits—at every age.</p>",
        closable: false
      }
    ]
  }
}