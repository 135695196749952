<template>
  <div>
    <iframe :src="content.file" allow="camera;gyroscope;accelerometer;magnetometer;xr-spatial-tracking;microphone;"></iframe>
  </div>
</template>

<script>
export default {
  name: 'TheAr',
  props: {
    content: Object
  }
}
</script>

<style scoped lang="scss">
div {
  position: absolute;
  top:0;
  left:0;
  height: 100vh;
  width: 100vw;
  background-color: black;
}
iframe {
  width: 100%;
  height: 100%;
  height: 100dvh;
}
</style>