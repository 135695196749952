import { createApp } from 'vue'
import { createPinia } from 'pinia'
import App from './App.vue'
import router from "./router";
import VueCookies from 'vue-cookies'

const pinia = createPinia();

createApp(App)
  .use(router)
  .use(pinia)
  .use(VueCookies)
  .mount('#app');