<template>
  <div @click="$emit('confirm')"><p>{{ name }}</p></div>
</template>

<script>
export default {
  name: 'previewBubble',
  props: {
    name: String
  }
}
</script>

<style scoped lang="scss">
div {
  position: absolute;
  top: calc(35% + 200px);
  left: 0;
  border-radius: 20px;
  text-align: center;
  width: 100%;
}
p {
  background: white;
  padding: 10px 30px;
  margin: 0;
  border-radius: 20px;
  display: inline;
}
</style>